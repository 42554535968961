import React from 'react';
import Helmet from 'react-helmet';
import {graphql, StaticQuery} from "gatsby";

const SEO_DATA = {
  description: 'Wax RDC',
  title: 'Absurd',
  url: 'wax-rdc.com',
  author: 'Wax RDC Ltd.',
  keywords: ['gatsby', 'site', 'tastydigital', 'scroller'],
  twitter: {
    handle: '',
    id: '',
    img: '',
  },
  facebook: {
    id: '',
    img: '',
  },
};

const SEO = () => (
    <StaticQuery
        query={graphql`
          query {
            wordpressSiteMetadata {
              description
              name
            }
           }
        `}
        render={data => (
          <Helmet>
              <meta property="fb:app_id" content={SEO_DATA.facebook.id}/>
              <meta property="og:title" content={data.wordpressSiteMetadata.name} />
              <meta property="og:type" content="website"/>
              <meta property="og:url" content={SEO_DATA.url}/>
              <meta property="og:image" content={SEO_DATA.facebook.img}/>
              <meta property="og:description" content={data.wordpressSiteMetadata.description}/>

              <meta name="twitter:card" content="summary_large_image"/>
              <meta name="twitter:creator" content={SEO_DATA.twitter.handle} />
              <meta name="twitter:site" content={SEO_DATA.twitter.id} />
              <meta name="twitter:title" content={data.wordpressSiteMetadata.name}/>
              <meta name="twitter:description" content={data.wordpressSiteMetadata.description}/>
              <meta name="twitter:domain" content={SEO_DATA.url}/>
              <meta name="twitter:image:src" content={SEO_DATA.twitter.img}/>

              <meta name="description" content={data.wordpressSiteMetadata.description}/>
              <meta name="keywords" content={SEO_DATA.keywords.join(', ')}/>
              <meta name="author" content={SEO_DATA.author}/>
              <title>{data.wordpressSiteMetadata.name}</title>
              <link rel="stylesheet" href="https://use.typekit.net/rjg7ktj.css"/>

              <html lang="en"/>
          </Helmet>
        )}
    />
);

export default SEO;
