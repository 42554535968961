const theme = {
  font: {
    primary: `league-gothic, sans-serif`,
    secondary: `myriad-pro, sans-serif`,
  },
  font_size: {
    small: 'font-size: 18px; line-height: 26px',
    regular: 'font-size: 21px; line-height: 26px',
    large: 'font-size: 30px; line-height: 40px',
    larger: 'font-size: 36px; line-height: 1.1666',
    xlarge: 'font-size: 48px; line-height: 56px',
  },
  color: {
    white: {
      regular: '#FFFFFF',
      dark: '#F6F6F6',
    },
    black: {
      lighter: '#6D6E70',
      light: '#58595B',
      regular: '#211E26',
    },
    green: {
      light: '#EDF4E0',
      regular: '#7ACE94',
      dark: '#379481',
    },
    primary: '#1C3B6A',
  },
  screen: {
    xs: '575px',
    sm: '767px',
    md: '991px',
    lg: '1199px',
  },
};

export default theme;
