import styled from 'styled-components';
import dash from '@static/deco/dash.svg';


export const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  @media (min-width: ${props => props.theme.screen.xs}) {
    max-width: 540px;
  }

  @media (min-width: ${props => props.theme.screen.sm}) {
    max-width: 720px;
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    max-width: 960px;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    max-width: 1200px;
  }
    
  ${props =>
    props.fluid &&
    `
    max-width: 1200px !important;
  `};
  
  .fund-blocks {
      max-width:100%;
      //margin-left:-16px;
      //margin-right:-16px;
      //height:100%;
      width:100%;
  }


`;

export const Section = styled.section`
  padding: 62px 0;
  overflow: hidden;
  background-color: ${props => props.theme.color.green.light};
  background-image: url(${dash});
  background-repeat: repeat-x;
  background-position: bottom;
  background-size: 24px 1px;
  // @media (max-width: ${props => props.theme.screen.md}) {
  //   padding: 96px 0;
  // }
  
  &#who-we-are,
  &#how-we-work,
  &#get-in-touch {
    border-top:10px solid ${props => props.theme.color.white.regular};
  }
   &#application-process {
   position:relative;
   padding-bottom:31px;
    h2 {
      color:${props => props.theme.color.green.light};
    }
   }
   &#available-innovate-uk-funds {
      padding-bottom:94px;
   }
  ${props =>
    props.accent &&
    `background-color: ${
      props.accent === 'secondary'
        ? props.theme.color.white.dark
        : props.theme.color.green.regular
    }`};
    
`;
